import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: ${props => props.theme.spacing.section};
  ${MEDIA.MAX_MEDIUM`
    grid-gap: calc(${props => props.theme.spacing.section} / 2);
  `};
`;
