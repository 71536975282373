import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Item from 'components/gallery/item';
import { Container, Wrapper, Header, LinkContainer } from './gallery.css';
import Box from 'components/box';
import Title from 'components/title';

const Gallery = ({ items, header, link, ...props }) => (
  <Wrapper {...props}>
    <Box>
      {header && (
        <Header>
          <Title as="h3">{header}</Title>
        </Header>
      )}
      <Container>
        {items.map((item, i) => (
          <Item {...item} key={i} />
        ))}
      </Container>
      {!!link && (
        <LinkContainer>
          <Link to={link}>See more...</Link>
        </LinkContainer>
      )}
    </Box>
  </Wrapper>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string,
  link: PropTypes.string,
};

Gallery.defaultProps = {
  header: null,
};

export default Gallery;
