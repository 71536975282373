import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  padding: ${props => props.theme.spacing.section} 0;
  ${MEDIA.MAX_MEDIUM`
    padding: calc(${props => props.theme.spacing.section} / 2) 0;
  `};
  background: ${props => (props.color ? props.color : null)};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;

  ${MEDIA.MEDIUM`
    display: block;
  `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  h3 {
    text-align: center;
    position: relative;
    font-size: 2em;
    &::after {
      content: '';
      padding: 2px 0;
      background-color: ${props => props.theme.colors.secondary};
      position: absolute;
      bottom: -35px;
      left: 50%;
      width: 100px;
      margin-left: -50px;
    }
    ${MEDIA.MAX_MEDIUM`
      font-size: 1.5em;
    `};
  }
`;

export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${props => props.theme.spacing.section};

  a {
    font-weight: bold;
  }
`;
