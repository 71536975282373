import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import Hero from 'components/hero';
import Section from 'components/section';
import Gallery from 'components/gallery';
import Box from 'components/box';
import Grid from 'components/grid';
import fetchRss, { mapArticles } from 'helpers/fetch-rss';
import RssFeed from 'components/rss-feed';

const Index = ({ data }) => {
  const [articles, setArticles] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchRss('https://tophotel.news/feed/')
      .then(mapArticles)
      .then(res => setArticles(res.items))
      .finally(() => setIsFetching(false))
      .catch(() => setIsFetching(false));
  }, []);

  return (
    <Layout>
      <Hero
        header={data.homeJson.content.childMarkdownRemark.frontmatter.title}
        subheader={
          data.homeJson.content.childMarkdownRemark.frontmatter.caption
        }
        image={data.homeJson.gallery[0].image.childImageSharp.fluid}
      />
      <Box spaced>
        <Grid>
          <Section fixedWidth="770px">
            <div
              dangerouslySetInnerHTML={{
                __html: data.aboutJson.content.childMarkdownRemark.html,
              }}
            />
          </Section>
          <Section fixedWidth="770px">
            <div
              dangerouslySetInnerHTML={{
                __html: data.whyUsJson.content.childMarkdownRemark.html,
              }}
            />
          </Section>
        </Grid>
      </Box>
      <Gallery
        header="Transaction References"
        items={data.referencesJson.gallery.slice(0, 3)}
        color="white"
        link="/references"
      />
      <Box>
        <RssFeed
          articles={articles}
          articlesToDisplay={4}
          isFetching={isFetching}
          seeMore
        />
      </Box>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          rawMarkdownBody
          frontmatter {
            title
            caption
          }
        }
      }
      gallery {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    referencesJson {
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    whyUsJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
