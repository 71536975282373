import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './hero.css';
import Box from 'components/box';
import Title from 'components/title';
import BackgroundImage from 'gatsby-background-image';
import { navHeight } from '../../constants/theme';

const style = {
  height: `calc(100vh - ${navHeight}px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const Hero = ({ header, subheader, image }) => (
  <BackgroundImage alt="hero-img" fluid={image} style={style}>
    <Box>
      <Container>
        <Title as="h2" size="largest">
          {header}
        </Title>
        <Title as="p" size="large" html={subheader} />
      </Container>
    </Box>
  </BackgroundImage>
);

Hero.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default Hero;
