import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './title.css';

const Title = ({ children, as = 'span', size, html }) => {
  if (html) {
    return (
      <Text as={as} size={size} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }

  return (
    <Text as={as} size={size}>
      {children}
    </Text>
  );
};

Title.defaultProps = {
  children: null,
  html: null,
};

Title.propTypes = {
  children: PropTypes.string,
  html: PropTypes.string,
  as: PropTypes.string,
  size: PropTypes.oneOf(['largest', 'large']),
};

export default Title;
