import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-weight: bold;
  margin: 0.8em 0.2em 0.2em;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 0.2em;
  font-weight: 300;
  font-size: ${props => props.theme.font.size.small};

  ${MEDIA.MEDIUM`
    margin-bottom: 4rem;
  `};
`;
