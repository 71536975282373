import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);
  h2 {
    color: white;
    padding-bottom: 15px;
  }
  p {
    color: #e6e6e6;
    margin: 0;
  }
`;
